import Vue from 'vue';
import {findIndex} from 'lodash-es'
export default {
    setItem(state, item){
        const index = findIndex(state.all, (o) => o.id === item.id);
        if ( index < 0 ){
            state.all.push(item);
        } else {
            Vue.set(state.all, index, item);
        }
    },

    setAllItems(state, items){
        state.all = items;
    },

    deleteItem(state, item_id){
        const index = findIndex(state.all, (o) => o.id === item_id);
        if ( index < 0 ) return false;

        Vue.delete(state.all, index);
    },

    setItemKey(state, {id, values}){
        const index = findIndex(state.all, (o) => o.id === id);
        if ( index < 0 ) return false;

        for(let i in values){
            const val = values[i];
            Vue.set(state.all[index], val.key, val.value);
        }
    },
}
