import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const getDefaultState = () => ({
    all: []
});

const state = getDefaultState()

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations: {
        ...mutations,
        resetState(state){
            Object.assign(state, getDefaultState())
        }
    }
}
