import axios from 'axios';
const store_name = 'content';
const store_multiple = 'contents';
export default {
    async store(context, {app_id, album_id, section_id, item}){
        if ( album_id ){
            item.album_id = album_id;
        } else if ( section_id ){
            item.section_id = section_id;
        }

        const response = await axios.post(
            '/api/app/' + store_multiple + '/' + app_id + (item.id ? '/' + item.id : ''),
            item
        );

        if ( response.data.status === false ) return response.data;

        context.commit('setItem', response.data[store_name]);
        return response.data;
    },

    async delete(context, {app_id, item}){
        const response = await axios.delete('/api/app/' + store_multiple + '/' + app_id + '/' + item.id);
        if ( response.data.status === false ) return response.data;

        context.commit('deleteItem', item.id);
        context.commit('resetPositionsInAlbum', item)
        await axios.put('/api/app/' + store_multiple + '/' + app_id + '/set_positions', {
            positions: context.getters.sortAndAlbum()
        })
        return response.data;
    },

    async updateContentsPosition(context, {app_id, section_id}){
        await axios.put('/api/app/' + store_multiple + '/' + app_id + '/set_positions', {
            positions: context.getters.sortAndAlbum(section_id)
        })
    }
}
