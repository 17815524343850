import {findIndex, filter, sortBy} from 'lodash-es';
export default {
    all: (state) => state.all,
    allWithContents: (state, getters, rootState, rootGetters) => {
        let albums = [];
        for(let i in getters.all){
            let album = JSON.parse(JSON.stringify(getters.all[i]));
            album.contents = rootGetters['appContents/byAlbumId'](album.id);
            if ( album.contents ) {
                album.contents = sortBy(album.contents, (o) => o.content_order)
            }
            albums.push(album);
        }

        return albums;
    },
    byId: (state, getters) => (id) => {
        const index = findIndex(getters.all, (o) => o.id === parseInt(id));
        if ( index < 0 ) return null;
        return getters.all[index];
    },
    byIdWithContents: (state, getters, rootState, rootGetters) => (id) => {
        const index = findIndex(getters.all, (o) => o.id === parseInt(id));
        if ( index < 0 ) return null;
        let album = JSON.parse(JSON.stringify(getters.all[index]));
        album.contents = JSON.parse(JSON.stringify(rootGetters['appContents/byAlbumId'](album.id)));
        album.contents = sortBy(album.contents, (o) => o.content_order);
        return album;
    },
    bySectionId: (state, getters) => (section_id) => {
        let albums = filter(getters.allWithContents, (o) => o.app_section_id === parseInt(section_id));
        if ( albums.length <= 0 ) return [];
        return sortBy(albums, (o) => o.section_order);
    },
    bySectionIdWithContents: (state, getters, rootState, rootGetters) => (section_id, max_contents) => {
        let albums = filter(getters.allWithContents, (o) => o.app_section_id === parseInt(section_id));
        if ( albums.length <= 0 ) return [];
        albums = JSON.parse(JSON.stringify(sortBy(albums, (o) => o.section_order)));
        for(let i in albums){
            let album = albums[i];
            let contents = rootGetters['appContents/byAlbumId'](album.id);
            contents = JSON.parse( JSON.stringify(contents) );
            contents = sortBy(contents, (o) => o.content_order)
            albums[i].contents_length = contents.length;
            albums[i].contents = max_contents ? contents.slice(0, max_contents) : contents;

        }

        return albums
    },
    positionOnly: (state) => {
        let to_return = {};
        for(let i in state.all){
            const album = state.all[i];
            to_return[album.id] = album.section_order;
        }

        return to_return;
    }
}
