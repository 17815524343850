import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css';
import it from 'vuetify/es5/locale/it'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {it},
        current: 'it'
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    }
});
