import axios from 'axios';
const store_name = 'section';
const store_multiple = 'sections';
export default {
    async store(context, {app_id, item}){
        const response = await axios.post(
            '/api/app/' + store_multiple + '/' + app_id + (item.id ? '/' + item.id : ''),
            item
        );

        if ( response.data.status === false ) return response.data;

        context.commit('setItem', response.data[store_name]);
        return response.data;
    },

    async setOrder(context, {app_id, order_per_id}){
        const response = await axios.post('/api/app/' + store_multiple + '/set_order/' + app_id, {
            order_per_id
        });

        return response.data
    },

    async delete(context, {app_id, item_id}){
        const response = await axios.delete('/api/app/' + store_multiple + '/' + app_id + '/' + item_id);
        if ( response.data.status === false ) return response.data;

        context.commit('deleteItem', item_id);
        return response.data;
    }
}
