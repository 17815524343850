import {findIndex, sortBy} from 'lodash-es';
export default {
    all: (state) => state.all,
    allWithContents: (state, getters, rootState, rootGetters) => {
        let sections = [];
        for(let i in getters.all){
            let section = JSON.parse(JSON.stringify(getters.all[i]));
            section.contents = JSON.parse(JSON.stringify(rootGetters['appContents/bySectionId'](section.id)));
            section.albums = JSON.parse( JSON.stringify(rootGetters['appAlbums/bySectionIdWithContents'](section.id)) );
            if ( section.contents ) {
                section.contents = sortBy(section.contents, (o) => o.content_order)
            }

            if ( section.albums ){
                section.albums = sortBy(section.albums, (o) => o.section_order);
            }

            let total_contents = section.contents.length;
            for(let i in section.albums){
                total_contents += section.albums[i].contents.length;
            }

            section.total_contents = total_contents;

            sections.push(section);
        }

        return sections;
    },
    byId: (state, getters) => (id) => {
        const index = findIndex(getters.all, (o) => o.id === parseInt(id));
        if ( index < 0 ) return null;
        return getters.all[index];
    },
    byIdWithContents: (state, getters, rootState, rootGetters) => (id) => {
        const index = findIndex(getters.all, (o) => o.id === parseInt(id));
        if ( index < 0 ) return null;
        let section = JSON.parse(JSON.stringify(getters.all[index]));
        section.contents = rootGetters['appContents/bySectionId'](section.id);
        if ( section.contents ) {
            section.contents = sortBy(section.contents, (o) => o.content_order)
        }
        section.albums = rootGetters['appAlbums/bySectionIdWithContents'](section.id, 4);
        if ( section.albums ) {
            section.albums = sortBy(section.albums, (o) => o.section_order);
        }
        return section;
    }
}
