import app from './modules/app/app';
import appAlbums from './modules/app/albums';
import appContents from './modules/app/contents';
import appSections from './modules/app/sections';
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appAlbums,
        appContents,
        appSections,
    },
    strict: true
})
