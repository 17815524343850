import axios from 'axios';
const store_name = 'album';
const store_multiple = 'albums';
export default {
    async store(context, {app_id, section_id, item}){
        const response = await axios.post(
            '/api/app/' + store_multiple + '/' + app_id + '/' + section_id + (item.id ? '/' + item.id : ''),
            item
        );

        if ( response.data.status === false ) return response.data;

        context.commit('setItem', response.data[store_name]);
        return response.data;
    },

    async delete(context, {app_id, item_id}){
        const response = await axios.delete('/api/app/' + store_multiple + '/' + app_id + '/' + item_id);
        if ( response.data.status === false ) return response.data;

        context.commit('deleteItem', item_id);
        return response.data;
    },

    async updateOrder(context, {app_id}){
        await axios.put('/api/app/' + store_multiple + '/' + app_id + '/set_positions', {
            positions: context.getters.positionOnly
        })
    }
}
