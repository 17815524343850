import {filter, findIndex} from 'lodash-es';
export default {
    all: (state) => state.all,
    byId: (state, getters) => (id) => {
        const index = findIndex(getters.all, (o) => o.id === parseInt(id));
        if ( index < 0 ) return null;
        return getters.all[index];
    },
    byAlbumId: (state, getters) => (album_id) => {
        return filter(getters.all, (o) => o.app_album_id === parseInt(album_id));
    },
    bySectionId: (state, getters) => (section_id) => {
        return filter(getters.all, (o) => o.app_section_id === parseInt(section_id));
    },
    sortAndAlbum: (state, getters, rootState, rootGetters) => (section_id) => {
        let to_return = {};
        for(let i in getters.all){
            const item = getters.all[i];
            if ( section_id ) {
                if ( item.app_album_id ) {
                    const album = rootGetters['appAlbums/byId'](item.app_album_id);
                    if ( ! album ) continue;
                    if (album.app_section_id !== section_id) continue;
                } else if ( item.app_section_id !== section_id ) {
                    continue;
                }
            }

            to_return[ item.id ] = {
                app_album_id: item.app_album_id,
                app_section_id: item.app_section_id,
                content_order: item.content_order
            }
        }

        return to_return;
    }
}
