import Vue from 'vue';
import {findIndex, filter, sortBy} from 'lodash-es';

export default {
    setItem(state, item){
        const index = findIndex(state.all, (o) => o.id === item.id);
        if ( index < 0 ){
            state.all.push(item);
        } else {
            Vue.set(state.all, index, item);
        }
    },

    setAllItems(state, items){
        state.all = items;
    },

    deleteItem(state, item_id){
        const index = findIndex(state.all, (o) => o.id === item_id);
        if ( index < 0 ) return false;

        Vue.delete(state.all, index);
    },

    resetPositionsInAlbum(state, item){
        let items_in_same_album = filter(state.all, (o) => o.app_album_id === item.app_album_id);
        if ( items_in_same_album.length <= 0 ) return null;
        items_in_same_album = JSON.parse(JSON.stringify(sortBy(items_in_same_album, (o) => o.content_order)));

        for(let i in items_in_same_album){
            const item_sa = items_in_same_album[i];
            const index = findIndex(state.all, (o) => o.id === item_sa.id);
            if ( index <= 0 ) continue;
            Vue.set(state.all[index], 'content_order', i);
        }
    },

    setItemKey(state, {id, values}){
        const index = findIndex(state.all, (o) => o.id === id);
        if ( index < 0 ) return false;

        for(let i in values){
            const val = values[i];
            Vue.set(state.all[index], val.key, val.value);
        }
    },

    setItemPosition(state, {id, new_position}){
        const index = findIndex(state.all, (o) => o.id === id);
        if ( index < 0 ) return false;

        const item = state.all[index];
        let all_items_in_container;
        if ( item.app_album_id ) {
            all_items_in_container = JSON.parse(JSON.stringify(filter(state.all, (o) => o.app_album_id === item.app_album_id)));
        } else {
            all_items_in_container = JSON.parse(JSON.stringify(filter(state.all, (o) => o.app_section_id === item.app_section_id)));
        }
        let position_to_set = null;
        if ( new_position >= all_items_in_container.length ){
            position_to_set = all_items_in_container.length;
        } else {
            position_to_set = new_position;
            for(let i in all_items_in_container){
                const item_in_album = all_items_in_container[i];
                if ( item_in_album.id === id ) continue;
                const item_in_album_position = item_in_album.content_order;
                if ( item_in_album_position >= position_to_set ){
                    const item_index_in_state = findIndex(state.all, (o) => o.id === item_in_album.id);
                    if ( item_index_in_state < 0 ) new Error('Errore, elemento ' + item_in_album.id + ' (item_in_album.id) non trovato nello state.');
                    Vue.set(state.all[item_index_in_state], 'content_order', item_in_album_position + 1);
                }
            }
        }

        Vue.set(state.all[index], 'content_order', position_to_set);

    }
}
