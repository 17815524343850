import Vue from 'vue';
import {findIndex} from 'lodash-es'
export default {
    setItem(state, item){
        const index = findIndex(state.all, (o) => o.id === item.id);
        if ( index < 0 ){
            state.all.push(item);
        } else {
            Vue.set(state.all, index, item);
        }
    },

    setAllItems(state, items){
        state.all = items;
    },

    deleteItem(state, item_id){
        const index = findIndex(state.all, (o) => o.id === item_id);
        if ( index < 0 ) return false;

        Vue.delete(state.all, index);
    }
}
