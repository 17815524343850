import axios from 'axios'
const setApp = (context, app) => {
    /*
        Reset di tutti gli stati
         */
    context.commit('resetState');
    context.commit('appSections/resetState', null, {root: true});
    context.commit('appAlbums/resetState', null, {root: true});
    context.commit('appContents/resetState', null, {root: true});

    /*
    Set delle info base dell'app:
        - app
        - graphics
        - contacts
     */

    context.commit('setApp', app);
    context.commit('setGraphics', app.graphic);
    context.commit('setContacts', app.contacts);

    /*
    Set delle sezioni
     */

    if ( app.sections ) {
        context.commit('appSections/setAllItems', app.sections, {root: true});
    }

    /*
    Set degli albums
     */

    if ( app.albums ) {
        context.commit('appAlbums/setAllItems', app.albums, {root: true});
    }

    /*
    Set dei contenuti
     */

    if ( app.contents ) {
        context.commit('appContents/setAllItems', app.contents, {root: true});
    }

};

window.settingsAbortController = null;

export default {
    async get(context, {data}){
        setApp(context, data);

        return data;
    },

    async getByEmail(context, email){
        const response = await axios.get('/api/app/get/by_user_email', {
            params: {email}
        });

        if ( response.data.status === true ){
            setApp(context, response.data.app);

            return response.data;
        }

        return response.data;
    },
    async create(context, information){
        const response = await axios.post('/api/app/create', information);
        if ( response.data.status === false ) return response.data;

        setApp(context, response.data.app);

        return response.data;
    },

    async storeSettings(context, {app_id, type}){
        if ( window.settingsAbortController ){
            window.settingsAbortController.abort();
            window.settingsAbortController = null;
        }

        window.settingsAbortController = new AbortController();

        const response = await axios.post('/api/app/'+type+'/' + app_id,
            context.getters.fullApp,
            {
                signal: window.settingsAbortController.signal
            }
        );
        if ( response.data.status === false ) return response.data;

        return response.data;
    }
}
