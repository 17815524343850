<template>
  <v-app>
    <v-main>
      <div v-if="$forceMobile && $vuetify.breakpoint.mdAndUp">
        <h1 class="text-center display-1">
          Questo sito funziona solo su dispositivi mobile
        </h1>
      </div>
      <template v-else>
        <v-container v-if="app && (($vuetify.breakpoint.smAndDown && app.app_disabled === true) || ($vuetify.breakpoint.mdAndUp && app.site_disabled === true))"
                     class="fill-height d-flex align-center justify-center">
          <v-card v-if="$vuetify.breakpoint.smAndDown && app.app_disabled === true">
            <v-card-title class="justify-center text-center error--text">
              Questa app è chiusa
            </v-card-title>
            <v-card-text class="text-center">
              <p class="text-center">App non più attiva.</p>
            </v-card-text>
          </v-card>
          <v-card v-else-if="$vuetify.breakpoint.mdAndUp && app.site_disabled === true">
            <v-card-title class="justify-center text-center error--text">
              Questo sito è chiuso
            </v-card-title>
            <v-card-text class="text-center">
              <p class="text-center">Sito non più attivo.</p>
            </v-card-text>
          </v-card>
        </v-container>
        <app-preview id="app-container"
                     v-else
        />
        <PWAPrompt copyTitle="Installa quest'app"
                   copyBody="Installa quest'app direttamente sul tuo dispositivo iOS. Per farlo leggi le istruzioni seguenti"
                   copyShareButtonLabel='1) Premi il bottone "Condividi"'
                   copyAddHomeButtonLabel='2) Premi "Aggiungi alla schermata Home"'
                   copyClosePrompt="Chiudi"
        />
        <v-bottom-sheet v-if="iOS"
                        v-model="showiOSNotificationSheet"
                        persistent
        >
          <v-card style="border-radius: 0"
                  :color="app.graphic.colors[0]"
                  :light="! isWhiteTextPreferred(app.graphic.colors[0])"
                  :dark="isWhiteTextPreferred(app.graphic.colors[0])"
          >
            <v-card-title>Notifiche</v-card-title>
            <v-card-text>
              Non perderti nessun aggiornamento su <strong>{{app.name}}</strong> ed abilita subito le notifiche istantanee!
            </v-card-text>
            <v-card-actions>
              <v-btn small text @click="canceliOSPush">
                Annulla
              </v-btn>
              <v-spacer />
              <v-btn :color="app.graphic.colors[1]"
                     :light="! isWhiteTextPreferred(app.graphic.colors[1])"
                     :dark="isWhiteTextPreferred(app.graphic.colors[1])"
                     :href="notificationsHREF"
              >
                Abilita
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>
      </template>
    </v-main>
  </v-app>
</template>
<script>
import AppPreview from 'dantebus-vue-artist-app/src/components/Layout';
import appInterface from 'dantebus-vue-artist-app/src/mixins/appInterface';
import {isSupported, getToken, getMessaging} from 'firebase/messaging'
import axios from "axios";
import {initializeApp} from "firebase/app";
import {register} from "register-service-worker";
import PWAPrompt from 'vue2-ios-pwa-prompt'

export default {
  components: {AppPreview, PWAPrompt},
  mixins: [appInterface],
  data: () => ({
    showiOSNotificationSheet: false
  }),
  watch: {
    '$route': {
      deep: true,
      handler(){
        this.setPageTitle();
      }
    }
  },
  computed: {
    notificationsHREF(){
      let app_url = 'https://' + (this.app.domain_name ? this.app.domain_name : 'app-' + this.app.id) + '.dantebus.com/' +
          '?id=' + this.app.id + '';
      const app_store_url = 'https://apps.apple.com/it/app/concorsi-e-artisti-dantebus/id1455140583';

      let url = 'https://notifications.dantebus.it/?link=' + encodeURIComponent(app_url);
      url += '&isi=1455140583';
      url += '&ibi=com.dantebus.artisti';
      url += '&ifl=' + encodeURIComponent(app_store_url);

      return url;
      //'https://notifications.dantebus.com/?link=https://'+(app.domain_name ? app.domain_name : 'app-' + app.id)+'.dantebus.com/?id%3D'+app.id+'&isi=1455140583&ibi=com.dantebus.artisti&ifl=https%3A%2F%2Fapps.apple.com%2Fit%2Fapp%2Fconcorsi-e-artisti-dantebus%2Fid1455140583'
    },
    iOS(){
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
  },
  methods: {
    isWhiteTextPreferred(hexColor){
      if ( ! hexColor ) return false
      // Converti il colore HEX in RGB
      const red = parseInt(hexColor.substring(1, 3), 16);
      const green = parseInt(hexColor.substring(3, 5), 16);
      const blue = parseInt(hexColor.substring(5, 7), 16);

      // Calcola il valore di luminosità usando la formula HSP
      const brightness = Math.sqrt(
          0.299 * (red * red) +
          0.587 * (green * green) +
          0.114 * (blue * blue)
      );

      // Se la luminosità è maggiore di 127.5, preferisci il testo scuro
      return brightness <= 127.5;
    },
    canceliOSPush(){
      window.localStorage.setItem('ios_push', 'close');
      this.showiOSNotificationSheet = false;
    },
    setPageTitle(){
      if ( this.$preview ) return null;

      if ( this.$route.name.indexOf('contacts') >= 0 ){
        document.title = this.app.name + ' - Contatti';
      } else if ( this.$route.name.indexOf('section') >= 0 ){
        document.title = this.app.name + ' - ' + this.section.name
      } else if ( this.$route.name.indexOf('home') >= 0 ){
        document.title = this.app.name;
      }  else if ( this.$route.name.indexOf('album') >= 0 ){
        document.title = this.app.name + ' - ' + this.album.name
      } else if ( this.$route.name.indexOf('content') >= 0 ){
        if ( this.content.title ) {
          document.title = this.app.name + ' - ' + (this.content.title ? this.content.title : '')
        } else {
          document.title = this.app.name + ' - ' + (this.content.title ? this.content.title : '')
        }
      }
    },
    async registerFCMToken(messaging, registration){
      try {
        if (await isSupported()) {
          console.log('registerFCMToken')
          const currentToken = await getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey: 'BDrzuV9tM7jTteQIlAshCFlhaHU3rheNcxwCCsM3zzkoxobsjQ_8EOIVQYGLDh9KAeEQlbPrtZA0F5k5ZDe3dcI'
          });

          if (currentToken) {
            await axios.post('https://strumenti-beta.dantebus.com/api/app/subdomain/' + this.$subdomain + '/registerToken', {
              token: currentToken
            })
          }
        }
      } catch (e) {
        console.log('E', e);
      }
    },
    startPushNotificationsIOS(){
      setTimeout(() => {
            alert('Per abilitare le notifiche occorre installare l\'app Concorsi e Artisti Dantebus')
            window.location = "https://apps.apple.com/it/app/concorsi-e-artisti-dantebus/id1455140583";
        }, 25);
      window.location = "concorsiartisti://notifications/" + this.app.id;
    },
    startPushNotifications(){
      if ( (! this.$forceMobile || this.$vuetify.breakpoint.smAndDown) && this.app.firebase_web_app_config) {
        const firebaseApp = initializeApp(JSON.parse(this.app.firebase_web_app_config));
        const messaging = getMessaging(firebaseApp);
        register('/service-worker.js?v=1.1&icon=' + this.app.graphic.icon_src + '&firebaseConfig=' + this.app.firebase_web_app_config, {
          error: (error) => {
            console.log('error', error);
          },
          registered: (registration) => {
            console.log('registered', registration);
          },
          ready: (registration) => {
            //messaging.useServiceWorker(registration);

            try {
              console.log('Notification', Notification)
              if (Notification) {
                console.log(Notification.permission);
                if (Notification.permission === "denied") {
                  console.log("bloccato dal browser");
                } else if (Notification.permission === "default" || Notification.permission === "granted") {
                  //Notification.requestPermission()
                  this.registerFCMToken(messaging, registration);
                }
              }
            } catch (e) {
              console.log('Non posso registrare le notifiche push')
            }
          }
        });
      }
    },
    appendPWAParams(){
      let themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', this.$app_colors[0]);

      let manifestLink = document.createElement('link');
      manifestLink.setAttribute('rel', 'manifest');
      manifestLink.setAttribute('href', 'https://strumenti-beta.dantebus.com/api/app/' + this.$app_id + '/manifest.json?origin=' + window.location.origin);

      let favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.href = this.$app_icon + '?width=16&height=16';

      const head = document.head || document.getElementsByTagName('head')[0];

      head.appendChild(themeColorMeta);
      head.appendChild(manifestLink);
      head.appendChild(favicon);

    }
  },
  mounted() {
    this.appendPWAParams();
    this.startPushNotifications();
    setTimeout(() => {
      this.setPageTitle();
    }, 1000);

    console.log('iOS', this.iOS);
    if ( this.app.id === 2 && this.iOS && window.localStorage.getItem('ios_push') !== 'close' ){
      if ( ! this.$route.query.notifications ) {
        this.showiOSNotificationSheet = true;
      } else {
        window.localStorage.setItem('ios_push', 'close');
        this.$router.push('/');
      }
    }
  }
}
</script>

<style lang="scss">
</style>
