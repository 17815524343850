import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import axios from 'axios';

axios.defaults.baseURL = 'http://localhost:8000';

import ArtistApp from 'dantebus-vue-artist-app/src/index';
import store from "./store";

let subdomain = null;
let is_id_only = false;
if (window.location.hostname === 'localhost' || window.location.hostname.indexOf('192.168') >= 0) {
    subdomain = 'app-872';
} else {
    subdomain = window.location.hostname.split('.')[0].toLowerCase();
}

if ( subdomain.indexOf('app-') === 0 ){
    subdomain = subdomain.replace('app-', '');
    subdomain = parseInt(subdomain);
    is_id_only = true;
}

Vue.prototype.$subdomain = subdomain;

const url = 'https://strumenti.dantebus.com/api/app/subdomain/' + subdomain;
axios.get(url, {
    headers: {
        "Accept": "*",
    }
})
    .then((response) => {
        Vue.prototype.$preview = false;
        Vue.prototype.$routePrefix = null;
        Vue.prototype.$forceMobile = is_id_only;
        if (response.data.status === false) {
            window.location.href = 'https://dantebus.com';
            Vue.prototype.$notFound = true;
            new Vue({
                vuetify,
                store,
                router,
                render: h => h(App)
            }).$mount('#app')
        } else {



            Vue.prototype.$notFound = false;
            Vue.prototype.$app_id = response.data.data.id;
            Vue.prototype.$app_colors = response.data.data.graphic.colors;
            Vue.prototype.$app_icon = response.data.data.graphic.icon_src;
            store.dispatch('app/get', {
                data: response.data.data
            })
                .then(() => {
                    Vue.use(ArtistApp, {
                        preview: false,
                        storageSystem: 'localStorage',
                        router,
                        store,
                        route_prefix: '',
                        platform: 'web',
                    });


                    Vue.config.productionTip = false
                    new Vue({
                        vuetify,
                        store,
                        router,
                        render: h => h(App)
                    }).$mount('#app')
                })
        }
    })


