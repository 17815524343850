import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const getDefaultState = () => ({
    imageZoomerInstance: null,
    app: {
        name: null,
        artist_type: null,
        artist_biography: null,
        description: null
    },
    graphics: {
        icon_src: null,
        avatar_src: null,
        intro_src: null,
        background_src: null,
        colors: [],
        artist_name_font : 'Merienda',
        artist_type_font : 'Italianno',
        primary_font : 'Lato',
    },
    contacts: []
});

const state = getDefaultState()

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations: {
        ...mutations,
        resetState(state){
            Object.assign(state, getDefaultState())
        }
    }
}
