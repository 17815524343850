import Vue from 'vue';
import {findIndex} from 'lodash-es';
export default {
    setApp(state, app){
        state.app = app;
    },
    setImageZoomerInstance(state, instance){
        state.imageZoomerInstance = instance
    },
    setGraphics(state, graphics){
        console.log('setGraphics', graphics)
        if ( graphics ){
            for(let i in graphics){
                Vue.set(state.graphics, i, graphics[i]);
            }
        }
    },
    setContacts(state, contacts){
        if ( contacts ){
            state.contacts = contacts
        }
    },
    setAppField(state, {name, value}){
        Vue.set(state.app, name, value);
    },
    setGraphicField(state, {field, value}){
        Vue.set(state.graphics, field, value);
    },
    setContact(state, {type, value}){
        const index = findIndex(state.contacts, (o) => o.type === type);
        if ( index < 0 ){
            state.contacts.push({
                type, value
            })
        } else {
            Vue.set(state.contacts, index, {
                type, value
            })
        }
    }
}
