import {findIndex} from 'lodash-es'
export default {
    fullApp: (state) => {
        return {
            ...state.app,
            graphics: state.graphics,
            contacts: state.contacts
        }
    },
    imageZoomerInstance: (state) => state.imageZoomerInstance,
    app: (state) => state.app,
    graphics: (state) => state.graphics,
    contacts: (state) => state.contacts,
    contactValueByType: (state) => (type) => {
        const contact_index = findIndex(state.contacts, (o) => o.type === type);
        if ( contact_index < 0) return null;
        return state.contacts[contact_index].value;
    }
}
